<template>
  <ion-page>
    <ion-content fullscreen>
      <h3>{{$t('surveyDescriptionText1')}}</h3>
      <template v-if="step == 1">
        <ion-card>
          <ion-card-content>
            <ion-list>
              <ion-row size="12">
                <template v-for="(item, index) in photoMapping[categoryNum].list" :key="index" >
                  <template v-if="checkList.indexOf(`${index+1}`) > -1">
                  <ion-col size="6">
                    <template v-if="photo[index+1] == undefined">
                      <ion-img src="/assets/images/common/no-image.png" @click="handlePhoto(index+1)" style="width: 100%;"/>
                    </template>
                    <template v-else> 
                      <ion-img
                        v-if="photo[index+1]"
                        :src="photo[index+1]"
                        @ionError="photo[index+1] = '/assets/images/common/no-image.png'"
                        @click="handlePhoto(index+1)"
                        style="width: 100%; height: 145px;"
                        />                            
                    </template>
                    <ion-button v-if="photo[index+1] === undefined" color="success" expand="full" @click="handlePhoto(index+1)">
                      <span>{{item}}</span>
                      <ion-icon :icon="cameraOutline"></ion-icon>
                    </ion-button>
                    <ion-button v-if="photo[index+1]" color="success" class="clear" expand="full" @click="removePhoto(index+1)">
                        <ion-icon :icon="trash"></ion-icon>
                      </ion-button>                      
                  </ion-col> 
                  </template>                  
                </template>
              </ion-row>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>
      <ion-button color="success" size="" expand="full" @click="handleNext">{{$t('buttonLabelNext')}}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage,
  IonContent, 
  IonButton, 
  IonCard, 
  IonCardContent,
  IonList,
  IonRow,
  IonCol,  
//  toastController,
  useIonRouter
} from '@ionic/vue'
import { onMounted, ref} from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { cameraOutline, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { addFileData, listData, updateData } from '@/api/board/common'
import { DataURIToBlobNew } from '@/utils/commonUtils'
//addData
export default {
  name: 'survey',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonCard, 
    IonCardContent,
    IonList,  
    IonRow,
    IonCol,    
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const router = useIonRouter()
    let step = ref(0)
    const modelCode = ref(undefined)
    const serialNumber = ref(undefined) 

    const categoryNum = ref(0)
    const checkList = ref([])
    
    //첨부파일
    const photo = ref([])
    const photoMapping = ref([])
    const photoFilename = ref([])

    //첨부파일 화면 출력 정보
    photoMapping.value[1] = {
      list : [t('labelPhoto9'), t('labelPhoto10'), t('labelPhoto11')]
    }
    photoMapping.value[2] = {
      list : [t('labelPhoto9'), t('labelPhoto10'), t('labelPhoto11'), t('labelPhoto12')]
    }
    photoMapping.value[3] = {
      list : [t('labelPhoto9'), t('labelPhoto10'), t('labelPhoto11'), t('labelPhoto12')]
    }
    photoMapping.value[4] = {
      list : [t('labelPhoto9'), t('labelPhoto10'), t('labelPhoto13')]
    }
    photoMapping.value[6] = {
      list : [t('labelPhoto23'), t('labelPhoto24'), t('labelPhoto25'), t('labelPhoto26')]
    }

    onMounted(async () => {
      await getOrderInfo()
    })
    
    const getOrderInfo = async () => {
      const apiUrl = '/api/appliances/order/orderdetail/'+Number(route.params.num)

      await listData(apiUrl).then(response => {        
        categoryNum.value = response.item_1_num
        if(response.message_text !== null && response.message_text != ''){
          checkList.value = response.message_text.split(',')
        }
        step.value = 1
      })
    }

    //첨부파일 처리
    const handlePhoto = async (index) => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })
      photo.value[index] = image.dataUrl
    }

    //파일 삭제
    const removePhoto = async (index) => {
      photo.value[index] = undefined
    }

    //저장 처리
    const handleNext = async () => {

      if(postOrderPicture()){
         await postOrder()
      }
    }

    //데이터 저장
    const postOrder = async () => {
    
        const apiUrl = `/api/appliances/order/`
        //data setting
        const formData = {}     
        formData[`num`] = Number(route.params.num)
        formData[`is_confirm`] = 'N'  
        //파일 정보 (파일 처리 이후 동작)
        photoFilename.value.forEach(value => {
          formData[`picture_${value.fileIndex}`] = value.fileName
        });
        
        await updateData(apiUrl, formData).then((response) => {      
          if(response){
            router.push({
              name: 'appliances-checkup',
              params: {
                isState: 'check'
              }
            })
          }  
        })        
    }

    //첨부파일 업로드
    const postOrderPicture = async () => {
      const apiUrl = '/api/order/picture'
      const orderNum = route.params.orderNum
      const photoCount = photoMapping.value[categoryNum.value].list.length
      let formData = new FormData()
      for(let i = 1; i <= photoCount; i++){
        if(photo.value[i] !== undefined){
          const file = DataURIToBlobNew(photo.value[i])
          const fileOfBlob = new File([file], `appliances_image_${i}.jpg`, {type:'image/jpeg'})
          photoFilename.value.push({
            fileIndex : i,
            fileName : `https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/hardware/${orderNum}/appliances_image_${i}.jpg`
          })
          formData.append('file', fileOfBlob)
        }
      }

      formData.append('ord_number', orderNum)

      await addFileData(apiUrl, formData).then((response) => {        
        return response.result === 'success'? true : false      
      })      

    }

    return {
      photo,
      photoMapping,
      step,
      categoryNum,
      checkList,
      modelCode,
      serialNumber,
      cameraOutline,
      trash,
      handlePhoto,
      removePhoto,
      handleNext,
      postOrderPicture,
      postOrder
    }
  }
}
</script>

<style>

ion-input {
  border-style: solid;
  border-width: 1px;
}

</style>